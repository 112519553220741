html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pac-logo:after {
  content: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
